import React from "react"
import Popup from "reactjs-popup"
import Layout from "../../components/layout"
import openingDetails from "../../data/openings-details.json"

import "../../styles/carrier.css"
import { Link } from "gatsby"

const getDate = date => {
  let newDate = Date.parse(date)
  let currentDate = Date.now()
  let diffTime = newDate - currentDate
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
  if (diffDays < 0) {
    return false
  }
  if (diffDays >= 7) {
    return `${Math.floor(diffDays / 7)} week${
      Math.floor(diffDays / 7) > 1 ? "s" : ""
    }`
  }
  return `${diffDays} day${diffDays > 1 ? "s" : ""}`
}

const CarrierPage = () => {
  return (
    <Layout>
      <div className="carrier-opening-container">
        <div className="container-heading-wrapper">
          <div className="container-heading-line"></div>
          <div className="container-heading">
            Current <span className="heading-bold-text">Openings</span>
          </div>
        </div>
        <div className="carrier-opening-main-container">
          {openingDetails &&
            openingDetails.map((opening, index) => (
              <div key={index} className="co-indi-div">
                <div className="co-indi-heading">{opening.role}</div>
                <div className="co-indi-content">
                  <div
                    dangerouslySetInnerHTML={{ __html: opening.shortDetail }}
                  />
                </div>
                <div className="card-base-wrapper">
                  <div className="co-indi-date">
                    {opening.closed || !getDate(opening.closedDate)
                      ? "Closed"
                      : `Closes in ${getDate(opening.closedDate)}`}
                  </div>
                  <div className="button-wrapper">
                    {opening.closed || !getDate(opening.closedDate) ? (
                      <div className="general-button co-indi-button-inactive">
                        Apply
                      </div>
                    ) : (
                      <div className="carrier-apply-button-wrapper">
                        {opening.closed ? (
                          ""
                        ) : (
                          <Popup
                            trigger={
                              <button className="apply-button-popup">
                                Apply
                              </button>
                            }
                            modal
                            closeOnDocumentClick
                          >
                            <div className="job-description-modal">
                              <div className="co-indi-heading">
                                {opening.role}
                              </div>
                              <div className="co-indi-content modal-content">
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: opening.details,
                                  }}
                                />
                              </div>
                              <div className="co-indi-date">
                                {opening.closed || !getDate(opening.closedDate)
                                  ? "Closed"
                                  : `Closes in ${getDate(opening.closedDate)}`}
                              </div>
                              <div className="button-wrapper special-wrapper">
                                {opening.closed ||
                                !getDate(opening.closedDate) ? (
                                  <div className="general-button co-indi-button-inactive">
                                    Apply
                                  </div>
                                ) : (
                                  <div className="carrier-apply-button-wrapper special-height">
                                    <button
                                      className="apply-button-popup"
                                      onClick={() =>
                                        window.open(opening.link, "_blank")
                                      }
                                    >
                                      Apply
                                    </button>
                                  </div>
                                )}
                              </div>
                            </div>
                          </Popup>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </Layout>
  )
}

export default CarrierPage
